// stylelint-disable
$gray-base:  #181b1e !default;
$gray-100:   lighten($gray-base, 85%);
$gray-200:   lighten($gray-base, 80%);
$gray-300:   lighten($gray-base, 70%);
$gray-400:   lighten($gray-base, 60%);
$gray-500:   lighten($gray-base, 50%);
$gray-600:   lighten($gray-base, 40%);
$gray-650:   lighten($gray-base, 25%);
$gray-700:   lighten($gray-base, 15%);
$gray-750:   lighten($gray-base, 12.5%);
$gray-800:   lighten($gray-base, 10%);
$gray-900:   lighten($gray-base, 5%);

$secondary:  $gray-600 !default;

$white:      #fff;

$blue:       #20a8d8 !default;
$red:        #f86c6b !default;
$orange:     #f8cb00 !default;
$yellow:     #ffc107 !default;
$green:      #4dbd74 !default;
$light-blue: #63c2de !default;

$body-bg:       $gray-800 !default;
$body-color:    $gray-200 !default;


// Components
//
// Define common padding and border radius sizes and more.

$border-color:                      $gray-900 !default;
$component-active-color:            $gray-750 !default;

// Aside

$aside-menu-color:                  $gray-100 !default;
$aside-menu-bg:                     $gray-700 !default;

// Avatars

$avatar-status-border-color:        $gray-700 !default;

// Dropdowns

$dropdown-bg:                       $gray-700 !default;
$dropdown-border-color:             $border-color !default;
$dropdown-divider-bg:               $gray-750 !default;

$dropdown-link-color:               $gray-200 !default;
$dropdown-link-hover-color:         $gray-200 !default;
$dropdown-link-hover-bg:            $gray-800 !default;

$dropdown-link-disabled-color:      $gray-600 !default;

$dropdown-header-color:             $gray-200 !default;

// Forms

$input-bg:                              $gray-650 !default;
$input-disabled-bg:                     $gray-700 !default;

$input-color:                           $gray-200 !default;
$input-border-color:                    $border-color !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-color:                     $input-color !default;

$input-placeholder-color:               $gray-400 !default;
$input-plaintext-color:                 $body-color !default;

$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-750 !default;
$input-group-addon-border-color:        $input-border-color !default;

$custom-control-indicator-bg:           $gray-300 !default;

$custom-control-indicator-disabled-bg:          $gray-200 !default;
$custom-control-label-disabled-color:           $gray-600 !default;

// Modals

$modal-content-bg:                  $gray-700 !default;
$modal-content-border-color:        $border-color !default;

$modal-header-border-color:         $border-color !default;

// Navs

$nav-link-disabled-color:           $gray-600 !default;

$nav-tabs-border-color:             $border-color !default;
$nav-tabs-link-hover-border-color:  $border-color $border-color $border-color !default;
$nav-tabs-link-active-color:        $gray-100 !default;
$nav-tabs-link-active-bg:           $gray-750 !default;
$nav-tabs-link-active-border-color: $border-color $border-color $border-color !default;

$tab-content-bg:                    $gray-700 !default;

// Navbar
$navbar-bg:                $gray-700 !default;
$navbar-color:             $gray-300 !default;
$navbar-hover-color:       $gray-200 !default;
$navbar-active-color:      $gray-200 !default;
$navbar-disabled-color:    $gray-600 !default;

// Pagination

$pagination-bg:                     $gray-750 !default;
$pagination-border-color:           $border-color !default;

$pagination-hover-bg:               $gray-800 !default;
$pagination-hover-border-color:     $border-color !default;

$pagination-disabled-color:         $gray-600 !default;
$pagination-disabled-bg:            $gray-900 !default;
$pagination-disabled-border-color:  $border-color !default;

// Breadcrumb
$breadcrumb-bg:            $gray-700 !default;
$breadcrumb-divider-color: $gray-100 !default;
$breadcrumb-active-color:  $gray-100 !default;

// Breadcrumb menu
$breadcrumb-menu-color:    $gray-100 !default;

// Jumbotron

$jumbotron-bg:             $gray-600 !default;

// Cards
$card-bg:                  $gray-700 !default;
$card-border-color:        $border-color !default;
$card-cap-bg:              $gray-750 !default;

// Sidebar
$sidebar-color:                       $gray-100 !default;
$sidebar-bg:                          $gray-700 !default;
$sidebar-borders: (
  right: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$sidebar-nav-link-active-bg:          $gray-750 !default;

// Footer

$footer-bg:                           $gray-700 !default;
$footer-color:                        $body-color !default;

// Progress bars

$progress-bg:                       $gray-650 !default;


// List group

$list-group-bg:                     $gray-750 !default;
$list-group-border-color:           $border-color !default;
$list-group-hover-bg:               $gray-800 !default;

$list-group-disabled-color:         $gray-600 !default;

$list-group-action-color:           $gray-200 !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;

// Switches

$switch-bg:                         $gray-750 !default;

// Tables

$table-bg:                    transparent !default;
$table-border-color:          $border-color !default;

$table-head-bg:               $gray-750 !default;
$table-head-color:            $body-color !default;

// List group with accent
$list-group-accent-divider-bg: $gray-800 !default;
